import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LeadForm from "../lead-form";
import LoanCalculatorV2 from "../loan-calculator-v2";
import LoanCalculator from "../loan-calculator/";
import RoiCalculator from "../roi-calculator/pages/calc";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={["/", "/quote", "/quote1"]} component={LeadForm} />
        <Route exact path="/loan-calculator" component={LoanCalculator} />
        <Route exact path="/roi-calculator" component={RoiCalculator} />
        <Route exact path="/loan-calculator-v2" component={LoanCalculatorV2} />
      </Switch>
    </Router>
  );
};

export default App;
